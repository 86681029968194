<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtPage />

    <UNotifications />
    <UModals />
  </div>
</template>

<script setup lang="ts">
const colorMode = useColorMode();
colorMode.preference = "dark";

const color = computed(() =>
  colorMode.value === "white" ? "white" : "#111827"
);

const { locale } = useI18n();

useHead({
  title: "Thunder - 中国CS2 HvH 服务器 - CS2 HvH Server",
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
    { name: "baidu-site-verification", content: "codeva-5bhYH6E1wP" },
    { name: "msvalidate.01", content: "A1770F9DC0579EB104659A67EF158DF3" },
    {
      "http-equiv": "content-security-policy",
      content: "upgrade-insecure-requests",
    },
    {
      hid: "description",
      name: "description",
      content:
        "中国 HvH 服务器 - l0ve HvH - CS2 HvH Server, l0veL HvH Server - HvH Servers - 自定义模型 - 高性能服务器提供者",
    },
    {
      hid: "og:title",
      property: "og:title",
      content: "Thunder - 中国CS2 HvH 服务器 - CS2 HvH Server",
    },
    {
      hid: "og:description",
      property: "og:description",
      content:
        "中国 HvH 服务器 - l0ve HvH - CS2 HvH Server, l0veL HvH Server - HvH Servers - 自定义模型 - 高性能服务器提供者",
    },
    {
      hid: "og:type",
      property: "og:type",
      content: "website",
    },
    {
      hid: "og:image",
      property: "og:image",
      content: "https://thunder.pub/background.jpg",
    },
    {
      hid: "og:url",
      property: "og:url",
      content: "https://thunder.pub/",
    },
    {
      property: "og:locale",
      content: locale.value,
    },
  ],
  link: [
    { rel: "icon", href: "/logo.png" },
    { rel: "apple-touch-icon", href: "/logo.png" },
  ],
  htmlAttrs: {
    lang: locale.value,
  },
});

const title =
  "Thunder CS2 HvH Server | China Biggest HvH Server | Better than l0veL | HvH Servers";
const description =
  "Thunder CS2 HvH Server | China Biggest HvH Server | Better than l0veL | HvH Servers | Custom Models | High Performance Server Provider | CS2 HvH 服务器 | 中国最大的 HvH 服务器 | 比 l0veL 更好 | HvH 服务器 | 自定义模型 | 高性能服务器提供者";

useSeoMeta({
  title,
  description,
  ogTitle: title,
  ogDescription: description,
  ogImage: "https://thunder.pub/background.jpg",
  ogUrl: "https://thunder.pub/",
  ogType: "website",
  ogLocale: locale.value,
  ogLocaleAlternate: ["zh-CN", "en-US"],
  appleMobileWebAppTitle: title,
  applicationName: title,
  appleMobileWebAppStatusBarStyle: "black",
  mobileWebAppCapable: "yes",
  googlebot: "max-snippet",
  robots: "index, follow, all",
  // twitterImage: "https://dashboard-template.nuxt.dev/social-card.png",
  // twitterCard: "summary_large_image",
});
</script>

<style>
.my-enter-active,
.my-leave-active {
  transition: opacity 0.3s;
}
.my-enter,
.my-leave-active {
  opacity: 0;
}
</style>
