import { default as indexMWGWUVBytOMeta } from "D:/CODE/TS/thunder.pub/pages/dashboard/index.vue?macro=true";
import { default as indexM3x88cYYv4Meta } from "D:/CODE/TS/thunder.pub/pages/index.vue?macro=true";
import { default as indexwRX7nXgiA8Meta } from "D:/CODE/TS/thunder.pub/pages/keys/index.vue?macro=true";
import { default as indexCPYOfFy1sEMeta } from "D:/CODE/TS/thunder.pub/pages/login/index.vue?macro=true";
import { default as index0NyKVt0xa2Meta } from "D:/CODE/TS/thunder.pub/pages/profile/index.vue?macro=true";
import { default as indexRugAiLHHzzMeta } from "D:/CODE/TS/thunder.pub/pages/register/index.vue?macro=true";
import { default as index4RFRVDferNMeta } from "D:/CODE/TS/thunder.pub/pages/servers/index.vue?macro=true";
import { default as indexhzTxqMrZInMeta } from "D:/CODE/TS/thunder.pub/pages/usersmanage/index.vue?macro=true";
import { default as thunderviR4rAjY2UMeta } from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as thunderviR4rAjY2U } from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexMWGWUVBytOMeta?.name ?? "dashboard___zh-CN",
    path: indexMWGWUVBytOMeta?.path ?? "/dashboard",
    meta: indexMWGWUVBytOMeta || {},
    alias: indexMWGWUVBytOMeta?.alias || [],
    redirect: indexMWGWUVBytOMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexMWGWUVBytOMeta?.name ?? "dashboard___en-US",
    path: indexMWGWUVBytOMeta?.path ?? "/en-US/dashboard",
    meta: indexMWGWUVBytOMeta || {},
    alias: indexMWGWUVBytOMeta?.alias || [],
    redirect: indexMWGWUVBytOMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexM3x88cYYv4Meta?.name ?? "index___zh-CN",
    path: indexM3x88cYYv4Meta?.path ?? "/",
    meta: indexM3x88cYYv4Meta || {},
    alias: indexM3x88cYYv4Meta?.alias || [],
    redirect: indexM3x88cYYv4Meta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexM3x88cYYv4Meta?.name ?? "index___en-US",
    path: indexM3x88cYYv4Meta?.path ?? "/en-US",
    meta: indexM3x88cYYv4Meta || {},
    alias: indexM3x88cYYv4Meta?.alias || [],
    redirect: indexM3x88cYYv4Meta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRX7nXgiA8Meta?.name ?? "keys___zh-CN",
    path: indexwRX7nXgiA8Meta?.path ?? "/keys",
    meta: indexwRX7nXgiA8Meta || {},
    alias: indexwRX7nXgiA8Meta?.alias || [],
    redirect: indexwRX7nXgiA8Meta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/keys/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRX7nXgiA8Meta?.name ?? "keys___en-US",
    path: indexwRX7nXgiA8Meta?.path ?? "/en-US/keys",
    meta: indexwRX7nXgiA8Meta || {},
    alias: indexwRX7nXgiA8Meta?.alias || [],
    redirect: indexwRX7nXgiA8Meta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/keys/index.vue").then(m => m.default || m)
  },
  {
    name: indexCPYOfFy1sEMeta?.name ?? "login___zh-CN",
    path: indexCPYOfFy1sEMeta?.path ?? "/login",
    meta: indexCPYOfFy1sEMeta || {},
    alias: indexCPYOfFy1sEMeta?.alias || [],
    redirect: indexCPYOfFy1sEMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexCPYOfFy1sEMeta?.name ?? "login___en-US",
    path: indexCPYOfFy1sEMeta?.path ?? "/en-US/login",
    meta: indexCPYOfFy1sEMeta || {},
    alias: indexCPYOfFy1sEMeta?.alias || [],
    redirect: indexCPYOfFy1sEMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index0NyKVt0xa2Meta?.name ?? "profile___zh-CN",
    path: index0NyKVt0xa2Meta?.path ?? "/profile",
    meta: index0NyKVt0xa2Meta || {},
    alias: index0NyKVt0xa2Meta?.alias || [],
    redirect: index0NyKVt0xa2Meta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index0NyKVt0xa2Meta?.name ?? "profile___en-US",
    path: index0NyKVt0xa2Meta?.path ?? "/en-US/profile",
    meta: index0NyKVt0xa2Meta || {},
    alias: index0NyKVt0xa2Meta?.alias || [],
    redirect: index0NyKVt0xa2Meta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexRugAiLHHzzMeta?.name ?? "register___zh-CN",
    path: indexRugAiLHHzzMeta?.path ?? "/register",
    meta: indexRugAiLHHzzMeta || {},
    alias: indexRugAiLHHzzMeta?.alias || [],
    redirect: indexRugAiLHHzzMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexRugAiLHHzzMeta?.name ?? "register___en-US",
    path: indexRugAiLHHzzMeta?.path ?? "/en-US/register",
    meta: indexRugAiLHHzzMeta || {},
    alias: indexRugAiLHHzzMeta?.alias || [],
    redirect: indexRugAiLHHzzMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: index4RFRVDferNMeta?.name ?? "servers___zh-CN",
    path: index4RFRVDferNMeta?.path ?? "/servers",
    meta: index4RFRVDferNMeta || {},
    alias: index4RFRVDferNMeta?.alias || [],
    redirect: index4RFRVDferNMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/servers/index.vue").then(m => m.default || m)
  },
  {
    name: index4RFRVDferNMeta?.name ?? "servers___en-US",
    path: index4RFRVDferNMeta?.path ?? "/en-US/servers",
    meta: index4RFRVDferNMeta || {},
    alias: index4RFRVDferNMeta?.alias || [],
    redirect: index4RFRVDferNMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/servers/index.vue").then(m => m.default || m)
  },
  {
    name: indexhzTxqMrZInMeta?.name ?? "usersmanage___zh-CN",
    path: indexhzTxqMrZInMeta?.path ?? "/usersmanage",
    meta: indexhzTxqMrZInMeta || {},
    alias: indexhzTxqMrZInMeta?.alias || [],
    redirect: indexhzTxqMrZInMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/usersmanage/index.vue").then(m => m.default || m)
  },
  {
    name: indexhzTxqMrZInMeta?.name ?? "usersmanage___en-US",
    path: indexhzTxqMrZInMeta?.path ?? "/en-US/usersmanage",
    meta: indexhzTxqMrZInMeta || {},
    alias: indexhzTxqMrZInMeta?.alias || [],
    redirect: indexhzTxqMrZInMeta?.redirect,
    component: () => import("D:/CODE/TS/thunder.pub/pages/usersmanage/index.vue").then(m => m.default || m)
  },
  {
    name: thunderviR4rAjY2UMeta?.name ?? undefined,
    path: thunderviR4rAjY2UMeta?.path ?? "/sitemap.xml",
    meta: thunderviR4rAjY2UMeta || {},
    alias: thunderviR4rAjY2UMeta?.alias || [],
    redirect: thunderviR4rAjY2UMeta?.redirect,
    component: thunderviR4rAjY2U
  }
]