import revive_payload_client_4sVQNw7RlN from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "D:/CODE/TS/thunder.pub/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "D:/CODE/TS/thunder.pub/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m9SjywKgWm from "D:/CODE/TS/thunder.pub/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "D:/CODE/TS/thunder.pub/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "D:/CODE/TS/thunder.pub/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "D:/CODE/TS/thunder.pub/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import i18n_yfWm7jX06p from "D:/CODE/TS/thunder.pub/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_LDumGYo2KH from "D:/CODE/TS/thunder.pub/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "D:/CODE/TS/thunder.pub/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "D:/CODE/TS/thunder.pub/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "D:/CODE/TS/thunder.pub/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "D:/CODE/TS/thunder.pub/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_1aypKNZ222 from "D:/CODE/TS/thunder.pub/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "D:/CODE/TS/thunder.pub/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "D:/CODE/TS/thunder.pub/node_modules/@nuxt/ui-pro/plugins/variables.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  i18n_yfWm7jX06p,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod
]